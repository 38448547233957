/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { RefType } from 'Type/Common.type';

import BrandListItemTooltipComponent from './BrandListItemTooltip.component';
import { TOOLTIP_OFFSET_LEFT, TOOLTIP_OFFSET_RIGHT, TOOLTIP_OFFSET_TOP } from './BrandListItemTooltip.config';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandListItemTooltip/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    item: state.BrandTooltipReducer,
    isTooltipVisible: state.BrandTooltipReducer.isTooltipVisible
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandListItemTooltip/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandListItemTooltip/Container/BrandListItemTooltipContainer */
export class BrandListItemTooltipContainer extends PureComponent {
     static propTypes = {
         item: PropTypes.shape({
             image: PropTypes.string,
             itemRef: RefType,
             description: PropTypes.string,
             isShowImages: PropTypes.bool
         }).isRequired,
         isTooltipVisible: PropTypes.bool.isRequired
     };

     containerFunctions = {};

     state ={
         x: 0,
         y: 0,
         overflowingX: false,
         overflowingY: false
     };

     componentRef = createRef();

     containerProps() {
         const {
             item: {
                 itemRef,
                 image,
                 isShowImages,
                 description
             },
             isTooltipVisible: isVisible
         } = this.props;

         const {
             x,
             y,
             overflowingY,
             overflowingX
         } = this.state;

         if (itemRef.current && isVisible) {
             /*
              ↓↓↓ Update tooltip position only when it's visible.
              Binding to state to prevent tooltip being visible
              without updated position ↓↓↓
             */
             this.setState(this.update.bind(this));
         }

         return {
             ref: this.componentRef,
             image,
             isVisible: isVisible && !overflowingY,
             isShowImages,
             description,
             isOverflowingX: overflowingX,
             isOverflowingY: overflowingY,
             x,
             y
         };
     }

     update() {
         const {
             item: {
                 itemRef: {
                     current: itemRef
                 }
             }
         } = this.props;

         const {
             current: {
                 tooltipRef: {
                     current: tooltipNode
                 }
             }
         } = this.componentRef;

         if (tooltipNode === null) {
             return;
         }

         const docWidth = document.documentElement.clientWidth;
         const hoverRect = itemRef.getBoundingClientRect();
         const tooltipRect = tooltipNode.getBoundingClientRect();

         // eslint-disable-next-line prefer-destructuring -- not destructuring is used intentionally
         const x = hoverRect.x;
         const y = hoverRect.y - hoverRect.height - TOOLTIP_OFFSET_TOP + (
             hoverRect.height - tooltipRect.height
         );

         const overflowingX = (x + tooltipRect.width) >= (window.scrollX + docWidth);
         const overflowingY = y <= 0;

         this.setState({
             overflowingX: false,
             overflowingY: false
         });

         if (overflowingX) {
             this.setState({ overflowingX: true });
         } else if (overflowingY) {
             this.setState({ overflowingY: true });
         }

         /*
            Tooltip x will be positioned to the left side of hovered object
            ↓↓↓ when it's edge is overflowing the screen ↓↓↓
         */
         const correctedX = overflowingX
             ? x - tooltipRect.width + hoverRect.width * TOOLTIP_OFFSET_LEFT
             : x + hoverRect.width * TOOLTIP_OFFSET_RIGHT;

         this.setState({
             x: correctedX,
             y
         });
     }

     render() {
         return (
             <BrandListItemTooltipComponent
               { ...this.containerFunctions }
               { ...this.containerProps() }
             />
         );
     }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps, mapDispatchToProps, null,
    { forwardRef: true })(BrandListItemTooltipContainer);

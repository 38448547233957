/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable */
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    mapDispatchToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';

/** @namespace Scandipwa/Route/UrlRewrites/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    urlRewrite: state.UrlRewritesReducer.urlRewrite,
    isLoading: state.UrlRewritesReducer.isLoading,
    requestedUrl: state.UrlRewritesReducer.requestedUrl,
    // category_sort: state.ProductListReducer.currentArgs.sort?.sortKey,
    category_sort: state.UrlRewritesReducer?.urlRewrite?.sort_by ?? "",
});

/** @namespace Scandipwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    getProps() {
        const {
            match,
            location,
            category_sort,
        } = this.props;

        return {
            match,
            location,
            category_sort,
            ...this.getTypeSpecificProps(),
        };
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer)
);

/**
 * Stripe compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { SET_STRIPE_CONFIG, SET_STRIPE_LISTS_METHODS, SET_STRIPE_METHODS, UPDATE_STRIPE_LOADING, SET_STRIPE_PAYMENT, SET_STRIPE_PAYMENT_TYPE } from './Stripe.action';

/** @namespace Scandiweb/Stripe/Store/StripePayment/Reducer/getInitialState */
export const getInitialState = () => ({
    StripeConfig: {},
    isStripeLoading: false,
    StripeListsMethods: [],
    stripeID: '',
    stripe: '',
    elements: '',
    stripeTYPE: '',
});

/** @namespace Scandiweb/Stripe/Store/StripePayment/Reducer/StripePaymentReducer */
export const StripePaymentReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_STRIPE_CONFIG:
        const { data } = action;

        return {
            ...state,
            StripeConfig: data
        };
    case SET_STRIPE_LISTS_METHODS:
        const { lists, id } = action;

        return {
            ...state,
            StripeListsMethods: lists,
            stripeID: id,
        };
    case UPDATE_STRIPE_LOADING:
        const { loading } = action;

        return {
            ...state,
            isStripeLoading: loading
        };
    case SET_STRIPE_METHODS:
        const { stripeID } = action;
        return {
            ...state,
            stripeID: stripeID
        };
    case SET_STRIPE_PAYMENT:
        const { stripe, elements } = action;
        return {
            ...state,
            stripe: stripe,
            elements:elements,
        };
    case SET_STRIPE_PAYMENT_TYPE:
        const { TYPE } = action;
        return {
            ...state,
            stripeTYPE: TYPE
        };
    default:
        return state;
    }
};

export default StripePaymentReducer;

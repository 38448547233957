/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PaypalExpressProvider } from '../../context/PaypalExpress';

const addPaypalExpressProvider = (member) => [
    (children) => (
        <PaypalExpressProvider>
            { children }
        </PaypalExpressProvider>
    ),
    ...member
];

export default {
    'Component/App/Component': {
        'member-property': {
            contextProviders: addPaypalExpressProvider
        }
    }
};

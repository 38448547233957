/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { HIDE_TOOLTIP, SHOW_TOOLTIP } from './BrandTooltip.action';

/** @namespace Scandiweb/AmastyShopbyBrand/Store/BrandTooltip/Reducer/getInitialState */
export const getInitialState = () => ({
    itemRef: {},
    isShowImages: false,
    description: '',
    image: '',
    isTooltipVisible: false
});

/** @namespace Scandiweb/AmastyShopbyBrand/Store/BrandTooltip/Reducer/BrandTooltipReducer */
export const BrandTooltipReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        itemRef,
        isShowImages,
        description,
        image
    } = action;

    switch (type) {
    case SHOW_TOOLTIP:
        return {
            ...state,
            itemRef,
            isShowImages,
            description,
            image,
            isTooltipVisible: true
        };
    case HIDE_TOOLTIP:
        return {
            ...state,
            isTooltipVisible: false
        };
    default:
        return state;
    }
};

export default BrandTooltipReducer;

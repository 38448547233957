/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import BrandListItemTooltip from '../../component/BrandListItemTooltip';

export const BRAND_LIST_ITEM_TOOLTIP = 'BRAND_LIST_ITEM_TOOLTIP';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
const BEFORE_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: <BrandListItemTooltip />,
        position: 90,
        name: BRAND_LIST_ITEM_TOOLTIP
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            BEFORE_ITEMS_TYPE
        }
    }
};

/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import Image from 'Component/Image';

import './BrandListItemTooltip.style';

// TODO : needs refactoring to be rendered once

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandListItemTooltip/Component/BrandListItemTooltipComponent */
export class BrandListItemTooltipComponent extends PureComponent {
    static propTypes = {
        description: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        isOverflowingX: PropTypes.bool.isRequired,
        isShowImages: PropTypes.bool.isRequired,
        isVisible: PropTypes.bool.isRequired,
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired
    };

    tooltipRef = createRef();

    renderImage() {
        const {
            image,
            isShowImages,
            isVisible
        } = this.props;

        if (image === '' || !isShowImages) {
            return null;
        }

        return (
            <div
              block="BrandListItemTooltip"
              elem="Image"
              mods={ { isVisible } }
            >
                <Image
                  src={ image }
                />
            </div>
        );
    }

    renderDescription() {
        const { description } = this.props;

        return (
            <div
              block="BrandListItemTooltip"
              elem="Content"
            >
                { description }
            </div>
        );
    }

    render() {
        const {
            description,
            isVisible,
            isOverflowingX,
            x,
            y
        } = this.props;

        if (description === '') {
            return null;
        }

        const style = {
            left: (`${x + window.scrollX }px`),
            top: (`${y + window.scrollY }px`)
        };

        return (
            <div
              block="BrandListItemTooltip"
              mods={ { isVisible, isOverflowingX } }
              ref={ this.tooltipRef }
              style={ style }
            >
                { this.renderDescription() }
                { this.renderImage() }
                <div
                  block="BrandListItemTooltip"
                  elem={ `Tail${ isOverflowingX ? 'Right' : 'Left' }` }
                />
            </div>
        );
    }
}

export default BrandListItemTooltipComponent;

/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyShopbyBrand/Query/BrandsUrlResolver/Query */
export class BrandsUrlResolverQuery {
    getQuery(path) {
        return new Field('brandUrlResolver')
            .addArgument('path', 'String!', path)
            .addFieldList(this.getBrandsUrlResolverFields());
    }

    getBrandsUrlResolverFields() {
        return [
            'id',
            'name',
            'type'
        ];
    }
}

export default new BrandsUrlResolverQuery();

/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const SHOW_TOOLTIP = 'SHOW_TOOLTIP';
export const HIDE_TOOLTIP = 'HIDE_TOOLTIP';

/** @namespace Scandiweb/AmastyShopbyBrand/Store/BrandTooltip/Action/showTooltip */
export const showTooltip = (itemRef, isShowImages, description, image) => ({
    type: SHOW_TOOLTIP,
    itemRef,
    isShowImages,
    description,
    image
});

/** @namespace Scandiweb/AmastyShopbyBrand/Store/BrandTooltip/Action/hideTooltip */
export const hideTooltip = () => ({
    type: HIDE_TOOLTIP
});

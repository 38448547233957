/**
 * Stripe compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const UPDATE_STRIPE_LOADING = 'UPDATE_STRIPE_LOADING';
export const SET_STRIPE_CONFIG = 'SET_STRIPE_CONFIG';
export const SET_STRIPE_LISTS_METHODS = 'SET_STRIPE_LISTS_METHODS';
export const SET_STRIPE_METHODS = 'SET_STRIPE_METHODS';
export const SET_STRIPE_PAYMENT = 'SET_STRIPE_PAYMENT';
export const SET_STRIPE_PAYMENT_TYPE = 'SET_STRIPE_PAYMENT_TYPE';

/** @namespace Scandiweb/Stripe/Store/StripePayment/Action/stripeLoader */
export const stripeLoader = (loading) => ({
    type: UPDATE_STRIPE_LOADING,
    loading
});

/** @namespace Scandiweb/Stripe/Store/StripePayment/Action/setStripeConfig */
export const setStripeConfig = (data) => ({
    type: SET_STRIPE_CONFIG,
    data
});

/** @namespace Scandiweb/Stripe/Store/StripePayment/Action/setlistStripePaymentMethods */
export const setlistStripePaymentMethods = (lists, id) => ({
    type: SET_STRIPE_LISTS_METHODS,
    lists,
    id
});

/** @namespace Scandiweb/Stripe/Store/StripePayment/Action/setStripeMethodsValue */
export const setStripeMethodsValue = (stripeID) => ({
    type: SET_STRIPE_METHODS,
    stripeID
});

/** @namespace Scandiweb/Stripe/Store/StripePayment/Action/setStripePayment */
export const setStripePayment = (stripe, elements) => ({
    type: SET_STRIPE_PAYMENT,
    stripe,
    elements
});

/** @namespace Scandiweb/Stripe/Store/StripePayment/Action/setStripePaymentType */
export const setStripePaymentType = (TYPE) => ({
    type: SET_STRIPE_PAYMENT_TYPE,
    TYPE
});

/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';

const addBrandsToState = (args, callback) => ({
    ...callback(...args),
    brandsConfig: {}
});

const getBrandsFromAction = (args, callback) => {
    const [, action] = args;
    const {
        type,
        config: {
            ambrandlist,
            ambrandslider,
            availableStores
        } = {}
    } = action;

    const combinedConfigs = {
        ambrandlist,
        ambrandslider,
        availableStores
    };

    if (type !== UPDATE_CONFIG) {
        return callback(...args);
    }

    return {
        ...callback(...args),
        brandsConfig: combinedConfigs
    };
};

export default {
    'Store/Config/Reducer/getInitialState': {
        function: addBrandsToState
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: getBrandsFromAction
    }
};

/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const PAYPAL_SCRIPT_ID = 'PAYPAL_SCRIPT_ID';

/* @namespace Scandiweb/PaypalExpress/Util/PayPal/initPayPal */
export const initPayPal = (paypalSdkUrl) => new Promise((resolve, reject) => {
    if (document.getElementById(PAYPAL_SCRIPT_ID)) {
        resolve();
        return;
    }

    if (!paypalSdkUrl) {
        reject();
        return;
    }

    const script = document.createElement('script');

    script.src = paypalSdkUrl;
    script.type = 'text/javascript';
    script.id = PAYPAL_SCRIPT_ID;
    script.async = true;
    script.defer = true;

    script.onerror = (err) => {
        reject(err, script);
    };

    script.onload = () => {
        resolve();
    };

    document.body.appendChild(script);
});

/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ChildrenType } from 'Type/Common.type';

import { initPayPal } from '../util/PayPal';

export const PaypalExpressContext = createContext({
    token: '',
    setToken: () => {},
    paypal: null,
    setPaypal: () => {}
});

PaypalExpressContext.displayName = 'PaypalExpressContext';

/** @namespace Scandiweb/PaypalExpress/Context/PaypalExpress/PaypalExpressProvider */
export const PaypalExpressProvider = ({ children }) => {
    const [token, setToken] = useState('');
    const [paypal, setPaypal] = useState(null);
    const sdkUrl = useSelector((state) => state.ConfigReducer?.paypalConfigs?.sdkUrl);

    const initializePaypal = async () => {
        if (window.paypal) {
            setPaypal(window.paypal);
            return;
        }

        if (!sdkUrl) {
            return;
        }

        await initPayPal(sdkUrl);
        setPaypal(window.paypal);
    };

    useEffect(() => {
        initializePaypal();
    }, [sdkUrl, window.paypal]);

    const value = {
        token,
        setToken,
        paypal,
        setPaypal
    };

    return (
        <PaypalExpressContext.Provider value={ value }>
            { children }
        </PaypalExpressContext.Provider>
    );
};

PaypalExpressProvider.displayName = 'PaypalExpressProvider';

PaypalExpressProvider.propTypes = {
    children: ChildrenType.isRequired
};

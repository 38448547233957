/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/*
 * disabled multiple eslint rules to match coding style of ScandiPWA Core
 * vvv
*/
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';

import { BILLING_AGREEMENTS } from '../../route/PayPalBillingAgreementViewPage/PayPalBillingAgreementViewPage.config';

export const MyAccount = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account" */
    'Route/MyAccount'
));

export const PayPalReviewPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "paypal" */
    '../../route/PayPalReviewPage'
));

const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: (
           <Route
             path={ withStoreRegex('/paypal/express/review') }
             render={ (props) => <PayPalReviewPage { ...props } /> }
           />
        ),
        position: 150
    },
    {
        component: (
            <Route
              path={ withStoreRegex('/paypal/billing_agreement/view/agreement/:agreementId') }
              render={ (props) => <MyAccount { ...props } selectedTab={ BILLING_AGREEMENTS } /> }
            />
        ),
        position: 160
    },
    {
        component: (
            <Route
              exact
              path={ withStoreRegex('/paypal/billing_agreement') }
              render={ (props) => <MyAccount { ...props } selectedTab={ BILLING_AGREEMENTS } /> }
            />
        ),
        position: 170
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};
